const commonMethods = {
    // "ISEXISTS": "0",//，是否存在；0不存在可删除，1存在不可删除

    // 查看
    showReadBtn(row) {
        return false;
    },
    // 修改
    showEditBtn(row) {
        if (Number(row.ISEDIT) === 1) {
            return false;
        } else {
            return true;
        }
    },
    // 删除
    showDelBtn(row) {
        if (Number(row.ISEDIT) === 1) {
            return false;
        } else {
            return true;
        }
    },
    spanMethod({ row, column, rowIndex, columnIndex }, data) {
        const JcdxidList = data.map(ele => ele.DXLXMC);
        if (!JcdxidList.some(ele => !!ele)) {
            return;
        }
        if ([2].includes(columnIndex)) {
            // 向前查找 只要遇到一个相同监测对象的 span为0
            if (!data[rowIndex].DXLXMC) return;
            if (rowIndex !== 0) {
                if (data[rowIndex - 1].DXLXMC === data[rowIndex].DXLXMC) {
                    return [0, 1];
                }
            }
            // 向后查找 每遇到一个相同监测对象的 span加一
            let tempIndex = rowIndex;
            while (JcdxidList[tempIndex] === data[rowIndex].DXLXMC) {
                tempIndex++;
            }
            return [(tempIndex - rowIndex), 1];
        }
    },
};
export { commonMethods };

