const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "对象类型",
                prop: "DXLXMC",
                minWidth: 140
            }, {
                label: "对象",
                prop: "DXMC",
                minWidth: 200
            }, {
                prop: 'SJLX',
                // label: '是否枚举类预警指标',
                label: '预警指标类型',
                minWidth: 160,
                transList: {
                    1: '数值类',
                    2: '枚举类',
                },
            }, {
                label: "预警指标",
                prop: "YJZBXZJGZSMC",
                // rowSelect: true,
                minWidth: 200
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                        isShow: true,
                        btnType: 'icon',
                        operateType: 'columnRead',
                        title: '查看',
                        icon: 'el-icon-view',
                        color: 'green',
                        showMethod: 'showReadBtn',
                    },
                    {
                        isShow: true,
                        btnType: 'icon',
                        operateType: 'columnEdit',
                        title: '修改',
                        icon: 'el-icon-edit',
                        color: 'green',
                        showMethod: 'showEditBtn',
                    }, {
                        isShow: true,
                        btnType: 'icon',
                        operateType: 'columnDelete',
                        title: '删除',
                        icon: 'el-icon-delete',
                        color: 'red',
                        showMethod: 'showDelBtn',
                    }
                ],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '对象类型',
            clearable: true,
            placeholder: "请选择对象类型",
            list: [],
            optionLabel: 'YJDXMC',
            optionValue: 'YJDXBM',
            value: 'DXLXBM',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export {
    selfData
};

