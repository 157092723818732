<template>
    <div class="warning-object-set-grid">
        <sn-table-group
            class="table"
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>

        <sn-dialog :dialogData="dialogData" @onSubmit="onSubmitTest">
            <el-form
                status-icon
                class="formDialog"
                ref="myDialog"
                :model="formUp"
                :rules="formUpRules"
                label-width="165px"
                width="50%"
                enctype="multipart/form-data"
            >
                <el-form-item label="对象类型" prop="dxlx">
                    <el-select
                        class="tableItemWidth"
                        v-model="formUp.dxlx"
                        filterable
                        placeholder="请选择"
                        @change="dxlxChange()"
                    >
                        <el-option
                            v-for="(item, index) in formUp.dxlxList"
                            :key="index"
                            :label="item.YJDXMC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="对象" prop="dx">
                    <el-select
                        class="tableItemWidth"
                        v-model="formUp.dx"
                        filterable
                        placeholder="请选择"
                        @change="dxChange()"
                    >
                        <el-option
                            v-for="(item, index) in formUp.dxList"
                            :key="index"
                            :label="item.MC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="预警指标类型" prop="yjzblx">
                    <el-select
                        class="tableItemWidth"
                        v-model="formUp.yjzblx"
                        filterable
                        placeholder="请选择"
                        @change="getYjzbList()"
                    >
                        <el-option
                            v-for="(item, index) in formUp.yjzblxList"
                            :key="index"
                            :label="item.NAME"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- 多选 -->
                <el-form-item
                    label="预警指标"
                    v-if="formUp.yjzblx == 1"
                    prop="yjzbMul"
                >
                    <el-select
                        class="tableItemWidth"
                        v-model="formUp.yjzbMul"
                        filterable
                        collapse-tags
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="(item, index) in formUp.yjzbList"
                            :key="index"
                            :label="item.ZBMC"
                            :value="item.ZBBM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- 单选 -->
                <el-form-item
                    label="预警指标"
                    v-if="formUp.yjzblx == 2"
                    prop="yjzb"
                >
                    <el-select
                        class="tableItemWidth"
                        v-model="formUp.yjzb"
                        filterable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="(item, index) in formUp.yjzbList"
                            :key="index"
                            :label="item.ZBMC"
                            :value="item.ZBBM"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </sn-dialog>
    </div>
</template>
<script>
import { commonData } from "../warning_common/sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../warning_common/commonMethodMixin";
import { commonMethods } from "./model/table-methods";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "warning_object_set_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            dialogData: {
                width: "560px",
                title: "",
                dialog: false,
                action: true,
            },
            formUp: {
                lx: 0,
                dxlx: null, //对象类型
                dxlxList: [],
                dx: null, //对象
                dxList: [],
                yjzblx: 1, //预警指标类型
                yjzblxList: [
                    {
                        CODE: 1,
                        NAME: "数值类", //对象指标 多选
                    },
                    {
                        CODE: 2,
                        NAME: "枚举类", //对象指标 单选
                    },
                ],
                yjzbMul: [], //对象指标
                yjzb: "", //对象指标
                yjzbList: [],
            },
            formUpRules: {
                dxlx: [
                    {
                        required: true,
                        message: "请选择对象类型",
                        trigger: "blur",
                    },
                ],
                dx: [
                    {
                        required: true,
                        message: "请选择对象",
                        trigger: "blur",
                    },
                ],
                yjzblx: [
                    {
                        required: true,
                        message: "请选择预警指标类型",
                        trigger: "blur",
                    },
                ],
                yjzb: [
                    {
                        required: true,
                        message: "请选择预警指标",
                        trigger: "blur",
                    },
                ],
                yjzbMul: [
                    {
                        required: true,
                        message: "请选择预警指标",
                        trigger: "blur",
                    },
                ],
            },
            commonData,
            commonMethods,
            selfData,
            infoPage: "",
            // 筛选项
            keyword: "", // 关键字
            yjxBm: "", // 对象类型选择的值
            exportFunId: "yj002",
            exportName: "预警对象设置",
        };
    },
    mounted() {
        this.getDxlxList();
    },
    methods: {
        ...mapActions([
            "GetYjdxInfo", // 获取预警项数据
            "GetYjxList", // 获取预警对象列表  类型
            "GetYjdxList", // 获取预警对象列表
            "SaveYjszInfo",
            "DeleteYjszData",
            "GetYjzbList",
        ]),
        // 上传 end
        resetData() {
            //重置
            this.dialogData.title = "";
            let formUp = this.formUp;
            formUp.dxlx = null; //对象类型
            formUp.dx = null; //对象
            formUp.dxList = [];
            formUp.id = ""; //对象
            formUp.yjzblx = 1; //预警指标类型
            formUp.yjzbMul = []; //预警指标
            formUp.yjzbList = [];
            formUp.yjzb = ""; //预警指标
        },
        async getTableData() {
            this.tableGroupAttributes.loading = true;
            let res = await this.GetYjdxInfo({
                keyWords: this.keyword, // String 关键字；对象名称
                yjxBm: this.yjxBm ? this.yjxBm : "", // String 预警项编码
                pageIndex: this.tableGroupAttributes.snPage.currentPage, // Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu, // Int 页面数据量
            });
            this.tableGroupAttributes.snTable.table.data =
                res && res.DATA ? res.DATA : [];
            this.tableGroupAttributes.snPage.count =
                res && res.TOTAL ? Number(res.TOTAL) : 0;
            this.tableGroupAttributes.loading = false;
            // console.log("this.tableGroupAttributes", this.tableGroupAttributes, res);
        },
        // 获取对象类型列表
        async getDxlxList() {
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetYjxList();
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
                this.formUp.dxlxList = result;
            }
        },
        // 对象类型改变
        dxlxChange() {
            this.getDxList();
            this.formUp.dx = ""; //对象指标
            this.formUp.yjzbMul = []; //对象指标
            this.formUp.yjzb = ""; //对象指标
            this.formUp.yjzbList = [];
        },
        // 对象改变
        dxChange() {
            this.formUp.yjzbMul = []; //对象指标
            this.formUp.yjzb = ""; //对象指标
            this.getYjzbList();
        },
        // 获取 对象列表
        async getDxList() {
            this.formUp.dx = "";
            let result = await this.GetYjdxList({
                itemCode: this.formUp.dxlx,
            });
            this.formUp.dxList = result == null ? [] : result;
        },
        handleChange(type, value) {
            console.log("type, value", type, value);
            let obj = value[0];
            switch (type) {
                // 搜索
                case "searchBtn":
                    this.getTableData();
                    break;
                case "search":
                    this[value[1]] = value[0];
                    if (value[1] === "DXLXBM") {
                        this.yjxBm = value[0];
                    }
                    this.getTableData();
                    break;
                case "buttonNew":
                    this.resetData();
                    this.formUp.lx = 0;
                    this.dialogData.title = "预警对象设置";
                    this.$nextTick(() => {
                        this.dialogData.dialog = true;
                    });
                    break;
                case "columnEdit":
                    this.formUp.id = obj.ID;
                    this.dialogData.title = obj.DXMC;
                    this.formUp.dxlx = obj.YJDXID; //对象类型
                    this.getDxList();
                    this.formUp.dx = obj.DXID;
                    this.formUp.yjzblx = obj.SJLX;
                    this.getYjzbList();
                    if (this.formUp.yjzblx == 1) {
                        this.formUp.yjzbMul = obj.YJZBXZJGZDMC.split(",");
                    } else {
                        this.formUp.yjzb = obj.YJZBXZJGZDMC;
                    }
                    this.dialogData.dialog = true;
                    break;
                case "columnDelete":
                    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        cancelButtonClass: "",
                    })
                        .then(() => {
                            this.deleteItem(obj.ID);
                        })
                        .catch(() => {
                            this.common.showMsg("已取消删除", "info");
                        });
                    break;
                case "buttonExport": // 导出按钮
                    this.exportData();
                    break;
                case "page": // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
            }
        },
        onSubmitTest() {
            this.$refs.myDialog.validate((valid) => {
                if (valid) {
                    let Yjzbxzjgzsmc = null;
                    let Yjzbxzjgzdmc = null;
                    let Mjsjly = null; //枚举数据来源：表名
                    let Pgzd = null; //评估
                    let enumid = null;
                    let Mjzd = null;

                    let LX = this.common.aryListFindOne(
                        this.formUp.dxList,
                        "ID",
                        this.formUp.dx
                    ).LX;

                    Mjsjly = this.formUp.yjzbList[0].ZBSJLYB;
                    // 数值类
                    if (this.formUp.yjzblx == 1) {
                        let list = [];
                        let mjzdList = [];
                        let pgList = [];
                        this.formUp.yjzbMul.map((item, index) => {
                            this.formUp.yjzbList.map(
                                (itemInner, indexInner) => {
                                    if (item == itemInner.ZBBM) {
                                        list.push(itemInner.ZBMC);
                                        mjzdList.push(itemInner.MJZD);
                                        if (itemInner.ZBSJLYBZD) {
                                            pgList.push(itemInner.ZBSJLYBZD);
                                        }
                                    }
                                }
                            );
                        });
                        Yjzbxzjgzsmc = list.join(",");
                        Yjzbxzjgzdmc = this.formUp.yjzbMul.join(",");
                        Mjzd = mjzdList.join(",");
                        Pgzd = pgList.join(",");
                    } else {
                        // 枚举类
                        let obj = this.common.aryListFindOne(
                            this.formUp.yjzbList,
                            "ZBBM",
                            this.formUp.yjzb
                        );
                        enumid = obj.ENUMID;
                        Yjzbxzjgzsmc = obj.ZBMC;
                        Yjzbxzjgzdmc = this.formUp.yjzb;

                        Mjzd = obj.MJZD;
                        Pgzd = obj.ZBSJLYBZD;
                    }

                    if (Yjzbxzjgzsmc.length == 0) {
                        this.common.showMsg("请选择预警指标", "warning");
                        return;
                    }

                    let target = {
                        Yjdxid: this.formUp.dxlx,
                        Lx: LX,
                        Dxlxbm: this.common.aryListFindOne(
                            this.formUp.dxlxList,
                            "ID",
                            this.formUp.dxlx
                        ).YJDXBM,
                        Dxlxmc: this.common.aryListFindOne(
                            this.formUp.dxlxList,
                            "ID",
                            this.formUp.dxlx
                        ).YJDXMC,
                        Dxid: this.formUp.dx,
                        Dxmc: this.common.aryListFindOne(
                            this.formUp.dxList,
                            "ID",
                            this.formUp.dx
                        ).MC,
                        Sjlx: this.formUp.yjzblx,
                        Yjzbxzjgzdmc: Yjzbxzjgzdmc,
                        Yjzbxzjgzsmc: Yjzbxzjgzsmc,
                        Id: this.formUp.id ? this.formUp.id : "",
                        ENUMID: enumid ? enumid : "", //枚举id
                        Mjsjly: Mjsjly ? Mjsjly : "", //枚举数据来源
                        Mjzd: Mjzd ? Mjzd : "", //枚举字段
                        Pgzd: Pgzd ? Pgzd : "", //评估字段
                    };
                    this.onSubmit(target);
                } else {
                    return false;
                }
            });
        },
        async onSubmit(target) {
            let result = await this.SaveYjszInfo({
                jsonStr: JSON.stringify(target),
            });
            this.loading = false;
            this.formUp.STATE = true;
            if (result.ISSUCCESS) {
                this.dialogData.dialog = false;
                this.common.showMsg("保存成功", "success");
                this.resetData();
                this.getTableData();
            } else {
                this.common.showMsg("保存失败", "error");
            }
        },
        // 删除操作
        async deleteItem(ids) {
            console.log("删除操作", ids);
            let result = await this.DeleteYjszData({
                ids: ids,
            });
            if (result.ISSUCCESS) {
                this.common.showMsg("删除成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        // 获取 预警指标
        async getYjzbList() {
            this.yjzb = "";
            this.yjzbMul = [];
            let lx = this.common.aryListFindOne(
                this.formUp.dxList,
                "ID",
                this.formUp.dx
            ).LX;
            let itemCode = this.common.aryListFindOne(
                this.formUp.dxlxList,
                "ID",
                this.formUp.dxlx
            ).YJDXBM;
            let result = await this.GetYjzbList({
                itemCode: itemCode, // 对象类型的编码
                sjlx: this.formUp.yjzblx, //数据类型
                lx: lx, //对象的类型
                yjdxId: this.formUp.dxlx, //预警对象ID
            });
            this.formUp.yjzbList = result;
        },
        async exportData() {
            if (!this.exportFunId) return;
            let exportFunId = this.exportFunId;
            let exportName = this.exportName ? this.exportName : "表格";
            request();

            console.log("this.exportFunId", this.exportFunId);
            console.log("this.exportName", this.exportName);

            function request() {
                const req = new XMLHttpRequest();
                let url = window.REQUEST_URL + "EarlyWarn/ExportYjData";
                let funId = exportFunId; // String 约定的id：预警预案:yj001；预警对象设置:yj002；预警配置:yj003；预警处置:yj004
                let exportUrl = `${url}?funId=${funId}`;
                req.open("GET", exportUrl, true);
                req.responseType = "blob";
                req.setRequestHeader("Content-Type", "application/json");
                req.setRequestHeader("token", localStorage.accessToken);
                req.onload = function () {
                    const data = req.response;
                    const blob = new Blob([data]);
                    const blobUrl = window.URL.createObjectURL(blob);
                    downloadFile(blobUrl);
                };
                req.send("");
            }

            function downloadFile(blobUrl) {
                console.log(blobUrl, "blobUrl");
                const a = document.createElement("a");
                a.setAttribute("style", "display:none");
                a.setAttribute("href", blobUrl);
                let filename = `${exportName}.xls`;
                a.setAttribute("download", filename);
                a.click();
                // document.body.removeChild(a);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.warning-object-set-grid {
    width: 100%;
    height: 100%;
    .table {
        width: 100%;
        height: 100%;
    }
}

.formDialog {
    .tableItemWidth {
        width: 280px;
    }
}
</style>
